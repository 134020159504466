/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import {
    handlePresenceConfirmation, handlePreloadMembersConfirmed,
    handleOpenScreenQuorumOnPanel, handleCloseScreenQuorumOnPanel
} from '../../functions/functions-quoruns';

/* --------------------------------------------------------------------------
| FILE QUÓRUM EVENTS PANEL
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| EVENT -> VERIFICA SE EXISTE ITEM NO LOCALSTORAGE, SE EXISTE, RENDERIZA O ELEMENTO NO SIDEBAR...
|-------------------------------------------------------------------------- */
// addEventListener('load', () => {
//     (localStorage.getItem('quorumStarted')) && handleOpenScreenQuorumOnPanel();
// });

addEventListener('load', () => {
    let quorumSection = document.querySelector('#section__panel__quorum');
    let sectionPropositionVote = document.querySelector('#section__panel__proposition__vote');
    let sectionPropositionRead = document.querySelector('#section__panel__proposition__read');
   
    if(quorumSection.dataset.quorum_started && !sectionPropositionVote.dataset.vote_started && !sectionPropositionRead.dataset.read_started){
        handleOpenScreenQuorumOnPanel();
    }
});

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE PRÉ LOAD DE TODOS OS MEMBROS CONFIRMADOS DO LOCALSTORAGE...
|-------------------------------------------------------------------------- */
addEventListener('load', handlePreloadMembersConfirmed);

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ABERTURA DO QUORUM NO PANEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("quorum-started") // Seleciona o canal de open...
    .listen(".quorum-started", (e) => { // Fica ouvindo...
        handleOpenScreenQuorumOnPanel(); // Chamada da função que cria o elemento do sidebar e seta no localstorage...
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ENCERRAMENTO DO QUORUM NO PANEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("quorum-closed") // Seleciona o canal de open...
    .listen(".quorum-closed", (e) => { // Fica ouvindo...
        handleCloseScreenQuorumOnPanel(); // Chamada da função que cria o elemento do sidebar e seta no localstorage...
        localStorage.removeItem('membersPresenceConfirmationQuorum'); // Limpa o localStorage...
        window.location.reload(); // Recarrega a página para limpar o html...
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE PRESENÇA CONFIRMADA NO QUORUM NO PANEL...
|-------------------------------------------------------------------------- */
window.Echo.channel('quorum-presence') // Seleciona o canal...
    .listen('.quorum-presence', (e) => { // Fica escutando...
        handlePresenceConfirmation(e.memberId);
    });

